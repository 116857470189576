<template>
  <div class="container">
    <div v-if="!lod && !ers">
      <div class="top">
        <div class="swiper-img">
          <van-swipe @change="onChange" :stop-propagation="false">
            <van-swipe-item v-for="(item, index) in imgList" :key="index">
              <div class="img">
                <van-image
                  v-if="item.valueType != 1"
                  fit="cover"
                  :src="item.value"
                  round
                  @click="lookAlls(item)"
                />
                <van-image
                  v-else
                  fit="cover"
                  :src="item.poster"
                  round
                  @click="lookAlls(item)"
                />
                <div
                  class="play plays"
                  v-if="item.valueType == 1"
                  @click="lookAlls(item)"
                >
                  <img src="../../assets/newReport/play.png" alt />
                </div>
              </div>
            </van-swipe-item>
            <template #indicator>
              <div class="custom-indicator" style="color: #ffffff">
                {{ current + 1 }}/{{ imgList.length }}
              </div>
            </template>
          </van-swipe>
          <div class="logo">
            <img src="../../assets/mall/mlogo.png" alt="" />
          </div>
          <div class="title">{{ info.vehicleName }}</div>
          <div class="vin">VIN：{{ info.vin }}</div>
          <div class="info">
            <div class="level">
              <div class="lbox">
                <div>技术状况等级</div>
                <div>{{ level }}</div>
              </div>
              <div
                v-if="level == '一级' || level == '二级'"
                class="score scores"
              >
                <span>{{ score }}</span>
                <span>分</span>
              </div>
              <div
                v-if="level == '三级' || level == '四级' || level == '五级'"
                class="score"
              >
                <span>{{ score }}</span>
                <span>分</span>
              </div>
            </div>
            <ul class="list">
              <li class="item" v-for="(item, index) in list" :key="index">
                <div>{{ item.title }}</div>
                <div class="question">
                  <div>{{ item.level }}</div>
                  <!-- <div>{{ item.question }}项问题</div>
                  <img
                    v-if="!item.question"
                    src="../../assets/mall/ms.png"
                    alt=""
                  />
                  <img v-else src="../../assets/mall/merror.png" alt="" /> -->
                </div>
              </li>
            </ul>
          </div>
          <div class="number">报告编号：{{ info.number }}</div>
          <div class="time">
            <div>评估师：{{ info.evaPersonName }}</div>
            <div>鉴定日期：{{ info.evaDate }}</div>
          </div>
        </div>
      </div>
      <div class="main">
        <van-tabs
          :ellipsis="false"
          v-model="index"
          scrollspy
          sticky
          background="#031C42"
          title-inactive-color="#5869E7"
          title-active-color="#ffffff"
          duration="0.4"
        >
          <van-tab
            class="tabs"
            v-for="(item, index) in port"
            :title="item.name"
            :key="index"
          >
            <div class="qlist">
              <div class="qtitle">{{ item.name }}</div>
              <div class="qmain">
                <div class="imgs" v-if="item.name == '骨架鉴定项目'">
                  <img class="imgs-i" src="../../assets/mall/mgj.png" alt="" />
                </div>
              </div>
              <div class="err" v-if="item.error.length">
                <div class="errtitle">
                  <div>鉴定项：</div>
                  <div class="eimg">
                    <img src="../../assets/mall/merror.png" alt="" />
                    <div>注意事项 {{ item.error.length }}</div>
                  </div>
                </div>
                <ul class="elist">
                  <li class="eitem" v-for="(ite, ind) in item.elist" :key="ind">
                    <div
                      class="eit"
                      v-for="(it, id) in ite"
                      :key="id"
                      @click="showPop(item, it, id)"
                    >
                      <img src="../../assets/mall/merror.png" alt="" />
                      <div>{{ it.name }}</div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="err">
                <div class="errtitle">
                  <div>鉴定项：</div>
                  <div class="eimg">
                    <img src="../../assets/mall/ms.png" alt="" />
                    <div>通过 {{ item.success.length }}</div>
                  </div>
                </div>
                <ul class="elist">
                  <li class="eitem" v-for="(ite, ind) in item.slist" :key="ind">
                    <div class="eit" v-for="(it, id) in ite" :key="id">
                      <img src="../../assets/mall/ms.png" alt="" />
                      <div>{{ it.name }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <van-popup
        v-model="show"
        round
        lock-scroll
        closeable
        position="bottom"
        :style="{ height: '450px' }"
      >
        <div class="popup">
          <div class="pbox" style="margin-bottom: 20px">
            <div class="ptitle">{{ params.name }}</div>
            <van-tabs
              :ellipsis="false"
              title-style="nav-title"
              title-active-color="#333333"
              title-inactive-color="#999999"
              background="#ffffff"
              v-model="active"
              sticky
              swipeable
              @click="onClick"
            >
              <van-tab
                v-for="(item, index) in params.list"
                :title="item.name + '(' + item.index + '/' + item.id + ')'"
                :key="index"
              >
              </van-tab>
            </van-tabs>
          </div>
          <div class="sdiv">
            <swiper :options="swiperOption" ref="mySwiper">
              <swiper-slide v-for="(item, index) in picList" :key="index">
                <div class="blok">
                  <div class="ic">
                    <van-image
                      @click="onImageClick(picList, index)"
                      round
                      fit="cover"
                      :src="item.value"
                    />
                  </div>
                  <div class="tbox">
                    <div class="txt">
                      <div>{{ item.key }}</div>
                      <div v-if="item.valueType == 1">
                        扣{{ item.paramValue }}分
                      </div>
                      <div v-if="item.valueType == 2">
                        减值{{ item.paramValue }}元
                      </div>
                    </div>
                    <div class="remark">{{ item.remarks }}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </van-popup>
      <div class="share" @click="myCopy">
        <img src="../../assets//mall/share.png" alt="" />
      </div>
    </div>
    <div id="copyInner" class="vinMessage">
      <!-- <input  id="copyInners"  type="text"> -->
       {{ vinContentMessage }}
    </div>
    <div class="lodi" v-if="lod && !ers">
      <img src="../../assets//mall/lod.gif" alt="" />
      <div>加载中</div>
    </div>
    <div class="einfo" v-if="ers">
      <img src="../../assets//mall/404.png" alt="" />
    </div>
  </div>
</template>
    <script>
let _that = "";
import {
  getVehicleImages_1_,
  getSimpleTask,
  getSimpleTechAppraisal,
} from "@/utils/http.js";
import { Toast } from "vant";
import { formatDate } from "@/utils/time.js";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { ImagePreview } from "vant";
export default {
  name: "",
  components: {
    swiper,
    swiperSlide,
  },
  props: [],
  data() {
    return {
      vinContentMessage: "",
      ers: false,
      lod: true,
      picList: [],
      active: 0,
      show: false,
      level: "",
      score: "",
      index: 0,
      // 图片
      imgList: [],
      // 当前页码
      current: 0,
      // 标题
      info: {
        title: "",
        vin: "",
        number: "",
        vehicleName: "",
        evaPersonName: "",
        evaDate: "",
      },
      list: [],
      port: [],
      params: "",
      swiperOption: {
        loop: false,
        // 显示分页
        pagination: {},
        on: {
          slideChangeTransitionStart: function () {
            _that.picList.forEach((item, index) => {
              if (index == this.activeIndex) {
                console.log(item, index, this.activeIndex, "===");
                _that.active = item.index;
                _that.params.list.forEach((ite, ind) => {
                  if (ite.name == item.name) {
                    ite.index = item.id + 1;
                  }
                });
              }
            });
          },
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  created() {
    this.vinContentMessage = window.location.href
    // document.title = "商用车检";
  },
  mounted() {
    _that = this;
    let id = this.$route.query.id;
    // 图片
    this.getImgList(id);
    this.getSimpleTasks(id);

    this.getSimpleTechAppraisals(id);
  },
  methods: {
    showPop(data, ikey, ikid) {
      let params = {
        name: data.name,
        list: [],
      };
      let picList = [];
      data.error.forEach((item, index) => {
        let num = 0;
        params.list.push({
          name: item.name,
          index: 1,
        });
        item.transformedArray = item.contents.reduce(
          (acc, { valueType, name, remarks, paramValue, images }) => {
            images.forEach((image) => {
              acc.push({
                remarks,
                paramValue,
                valueType,
                name: item.name,
                key: name,
                index: index,
                value: image,
              });
            });
            return acc;
          },
          []
        );
        item.contents.forEach((ite, ind) => {
          num += ite.images.length;
          params.list.forEach((items) => {
            if (item.name == items.name) {
              items.id = num;
            }
          });
        });
      });
      data.error.forEach((item, index) => {
        console.log(item.transformedArray, "==="),
          item.transformedArray.forEach((it, id) => {
            picList.push({
              value: it.value,
              remarks: it.remarks,
              valueType: it.valueType,
              paramValue: it.paramValue,
              name: it.name,
              index: it.index,
              id,
              key: it.key,
            });
          });
      });
      this.params = params;
      this.picList = picList;
      this.show = true;
      setTimeout(() => {
        if (ikey) {
          console.log(ikey);
          this.active = ikid;
          let array = this.picList;
          for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if (element.name == ikey.name) {
              this.$refs.mySwiper.swiper.slideTo(index, 500, false);
              return;
            }
          }
        }
      });
    },
    onClick(name) {
      let key = this.params.list[this.active].name;
      let array = this.picList;
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (element.name == key) {
          this.params.list[this.active].index = 1;
          console.log(element.name, index, key);
          this.$refs.mySwiper.swiper.slideTo(index, 500, false);
          return;
        }
      }
    },
    async getSimpleTechAppraisals(taskId) {
      const { data: res } = await getSimpleTechAppraisal({ taskId });
      //console.log(res, "====111");
      if (res.resultStates != 0) {
        Toast(res.message);
        this.ers = true;
        return;
      }
      let list = [];
      let arr = [];
      res.content.forEach((item) => {
        if (!item.success) {
          this.level = item.level;
          this.score = item.score;
        } else {
          list.push(item);
          arr.push({
            title: item.name,
            question: item.error.length,
            level: item.level,
          });
        }
      });
      this.list = arr;
      list.forEach((item) => {
        if (item.error.length) {
          item.elist = item.error.reduce((resultArray, item, index) => {
            let chunkIndex = Math.floor(index / 2);
            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = []; // start a new chunk
            }
            resultArray[chunkIndex].push(item);
            return resultArray;
          }, []);
        }
        if (item.success.length) {
          item.slist = item.success.reduce((resultArray, item, index) => {
            let chunkIndex = Math.floor(index / 2);
            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = []; // start a new chunk
            }
            resultArray[chunkIndex].push(item);
            return resultArray;
          }, []);
        }
      });
      this.port = list;
      this.lod = false;
      // this.showPop(list[0]);
    },
    async getSimpleTasks(taskId) {
      const { data: res } = await getSimpleTask({ taskId });
      //console.log(res, "====");
      if (res.resultStates != 0) {
        Toast(res.message);
        return;
      }
      this.info = res.content;
    },
    // 获取图片
    async getImgList(id) {
      const { data: res } = await getVehicleImages_1_({
        taskId: id,
        isSimple: true,
      });
      // //console.log(res);
      if (res.resultStates != 0) {
        Toast(res.message);
        return;
      }
      res.content.forEach((item, index) => {
        // //console.log(item);
        item.imgList.forEach((items, ind) => {
          items.index = index;
          this.imgList.push(items);
        });
      });
      this.$store.commit("setImg", res.content);
    },
    // 轮播图事件
    onChange(index) {
      this.current = index;
    },
    lookAlls(ite, index) {
      let data = { id: ite.index, name: ite.name };
      if (index) {
        this.list.forEach((item, ind) => {
          //   //console.log(item, ind, index);
          if (index == ind) {
            data.id = item.imgList[0].index;
            data.name = item.imgList[0].name;
          }
        });
      }
      this.$store.commit("setImgKey", data);
      this.$router.push("/swiper");
    },
    // 点击图片
    onImageClick(list, index) {
      let arr = [];
      list.forEach((item) => {
        arr.push(item.value);
      });
      ImagePreview({
        images: arr,
        startPosition: index,
        loop: false,
      });
    },
    myCopy() {
      const range = document.createRange();
      range.selectNode(document.getElementById("copyInner"));
      const selection = window.getSelection();
      if (selection.rangeCount > 0) selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("Copy"); // 执行浏览器复制命令
      if (document.execCommand("copy")) {
        Toast("复制成功,请在浏览器打开");
      }
    },
  },
};
</script>
    <style lang='less'  scoped>
#copyInner {
  position: fixed;
  left: -100000000px;
}
.container {
  min-height: 100vh;
  background: #031c42;
  font-family: PingFangSC-Medium, PingFang SC;
  padding-bottom: 20px;
}
.top {
  width: 375px;
  min-height: 546px;
  background: url("../../assets//mall/mbg.jpg");
  background-size: 100% 100%;
  box-sizing: border-box;
  padding-top: 135px;
  padding-bottom: 20px;
}
.swiper-img {
  width: 345px;
  min-height: 300px;
  margin: 0 auto;
  background: url("../../assets//mall/mibg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 7px;
  box-sizing: border-box;
  padding: 15px;
  position: relative;
}
.img {
  width: 100%;
  height: 177px;
  border-radius: 7px;
}

.img > .van-image {
  width: 100%;
  height: 100%;
  border-radius: 7px;
}
.play {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 30px;
  left: 60px;
}

.plays {
  width: 50px;
  height: 50px;
  top: 80px;
  left: 164px;
}

.play > img {
  width: 100%;
  height: 100%;
}

.custom-indicator {
  position: absolute;
  width: 42px;
  height: 16px;
  right: 15px;
  bottom: 15px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}
.logo {
  width: 67px;
  height: 67px;
  position: absolute;
  right: 0;
  top: 0;
}
.logo > img {
  width: 100%;
  height: 100%;
}
.title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 17px;
  color: #333333;
  margin: 10px 0 2px 0;
  line-height: 24px;
}
.vin {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
}
.info {
  width: 315px;
  // height: 259px;
  background: #f6f7fb;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  margin: 10px 0 5px 0;
}
.number {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 10px;
  color: #333333;
  line-height: 14px;
}
.time {
  height: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 10px;
  color: #333333;
  display: flex;
  align-items: center;
  margin-top: 2px;
}
.time > div:nth-of-type(1) {
  margin-right: 30px;
}
.level {
  height: 79px;
  box-sizing: border-box;
  display: flex;
  padding: 13px 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #333333;
  align-items: center;
  justify-content: space-between;
}
.score {
  width: 53px;
  line-height: 54px;
  background: url("../../assets/mall/micon.png");
  background-size: 100% 100%;
  text-align: center;
  font-size: 9px;
  font-weight: 500;
  color: #ffffff;
  font-family: PingFangSC, PingFang SC;
  text-shadow: 0px 1px 2px #f0676b;
}
.scores {
  background: url("../../assets/mall/22.png");
  background-size: 100% 100%;
  text-shadow: 0px 1px 2px #3db3a5;
}
.score > span:nth-of-type(1) {
  font-size: 14px;
}
.lbox {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #333333;
}
.lbox > div:nth-of-type(2) {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  margin-top: 6px;
}

.item {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;
  height: 29px;
}
.question {
  display: flex;
  align-items: center;
}
.question img {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  object-fit: scale-down;
}
.item:nth-child(odd) {
  background-color: #ffffff;
}
.main {
  width: 345px;
  margin: 0 auto;
}
.qlist {
  width: 345px;
  min-height: 100px;
  background: #ffffff;
  border-radius: 7px;
  margin-top: 15px;
  padding-bottom: 15px;
}
.qtitle {
  width: 345px;
  line-height: 48px;
  background: linear-gradient(136deg, #4060d4 0%, #97b5ff 100%);
  box-shadow: inset 1px 1px 2px 0px rgba(71, 203, 255, 0.99),
    inset 0px 1px 1px 0px #bdf5ff;
  border-radius: 7px 7px 0px 0px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  text-indent: 12px;
}
.qmain {
  box-sizing: border-box;
  padding: 0 15px;
}
.imgs {
  width: 315px;
  height: 117px;
}
.imgs-i {
  width: 315px !important;
  height: 117px !important;
}
// .imgs > img:nth-of-type(2) {
//   width: 159px;
//   height: 103px;
// }
.qlist img {
  width: 12px;
  height: 12px;
  object-fit: scale-down;
}
.err {
  box-sizing: border-box;
  padding: 0 15px;
}
.errtitle {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  align-items: center;
  display: flex;
  width: 315px;
  height: 40px;
  background: #f6f7fb;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  padding: 0 10px;
  margin-top: 10px;
  border-radius: 4px 4px 0px 0px;
}
.errtitle > div:nth-of-type(1) {
  font-weight: 800;
  margin-right: 1px;
}
.eimg {
  display: flex;
  align-items: center;
}
.eimg img {
  margin-right: 4px;
}
.elist {
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0px 0px 4px 4px;
}
.eitem {
  width: 100%;
  //   width: 156px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #333333;

  display: flex;
  border-bottom: 1px solid #e0e0e0;
}
.eitem:nth-last-child(1) {
  border-bottom: none;
}

.eit {
  width: 152px;
  min-height: 30px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 2px 10px;
}
.eit:nth-child(odd) {
  border-right: 1px solid #e0e0e0;
  width: 161px;
}
.eit div{
  line-height: 20px;
}
.eitem img {
  margin-right: 4px;
}
.eitem:nth-child(even) {
  background: #f6f7fb;
}
/deep/.main .van-tab--active > .van-tab__text {
  font-size: 19px;
}
/deep/ .main .van-tabs__line {
  width: 25px;
  height: 1px;
  border-bottom: 3px solid #7ea1fd;
  background: none;
  left: -20px;
}
/deep/ .main .van-tabs__nav--line.van-tabs__nav--complete {
  padding-left: 0;
}
/deep/.main .van-tabs__wrap--scrollable .van-tab {
  padding-left: 0;
  margin-right: 10px;
}
/deep/.main .van-tabs--line .van-tabs__wrap {
  height: 52px;
}
.popup {
  box-sizing: border-box;
  padding: 16px 0px;
}
.ptitle {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 19px;
  color: #333333;
  margin-bottom: 18px;
}
/deep/ .popup .van-tabs__line {
  width: 0px;
  height: 0px;
  //   box-shadow: 0px -1px 3px 1px #9d6e31;
  border: none;
  background: none;
}
/deep/ .popup .van-tabs__nav--line.van-tabs__nav--complete {
  padding: 0;
}
/deep/.popup .van-tabs__wrap--scrollable .van-tab {
  padding: 0;
  margin-right: 10px;
}
/deep/.popup .van-tab {
  // width: 20px !important;
  flex: none !important;
  height: 34px;
}
/deep/.popup .van-tabs--line .van-tabs__wrap {
  height: 34px;
}
.ic {
  //   width: 315px;
  height: 177px;
}
.ic > .van-image {
  //   width: 100%;
  height: 177px;
  border-radius: 7px 7px 0 0;
  text-align: center;
  display: flex;
  justify-content: center;
}
/deep/ .ic .van-image {
  width: 335px;
  height: 177px;
  margin: 0 auto;
}
.tbox {
  width: 335px;
  box-sizing: border-box;
  background: #f6f7fb;
  padding: 0 20px;
  margin: 0 auto;
  height: 84px;
  border-radius: 0px 0px 6px 6px;
}
.txt {
  display: flex;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  height: 36px;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  // background: #f6f7fb;
}
.blok {
  //   width: 315px;
  //   background: #f6f7fb;
  border-radius: 6px;
}
.remark {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  line-height: 36px;
  // background: #f6f7fb;
}
.txt > div:nth-of-type(1) {
  margin-right: 4px;
}
.ind {
  line-height: 172px;
  //   background: #3db3a5;
  text-align: center;
}
.pbox {
  box-sizing: border-box;
  padding: 0 20px;
}
.sdiv {
  width: 375px;
  //   margin: 0 auto;
}
.lodi {
  text-align: center;
  color: #006cff;
  font-size: 16px;
  padding-top: 200px;
}
.lodi img {
  width: 200px;
  height: 200px;
}
.einfo {
  text-align: center;
  padding-top: 250px;
}
.einfo img {
  width: 200px;
  height: 150px;
}
/deep/ .main .van-sticky--fixed {
  padding-left: 14px;
}
.share {
  position: fixed;
  right: 10px;
  bottom: 200px;
}
.share img {
  width: 60px;
  height: 60px;
}
</style>